/** @jsx jsx */

import { Fragment, useState } from "react"
import { jsx, css } from "@emotion/core"
import {
  Box,
  Flex,
  Input,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Link
} from "@chakra-ui/core"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import LightHoverButton from "../components/chakraOverrides/LightHoverButton"
import ContactForm from "../components/ContactForm"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import { AiFillFacebook, AiFillLinkedin } from 'react-icons/ai'
import { FaTelegramPlane } from 'react-icons/fa'

const GoogleMapsIframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`

const SocialLink = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  svg:first-of-type {
    margin-inline-end: 0.5ch;
  }
`

const Footer = ({ intl, isOpen, onOpen, onClose }) => {
  const [email, setEmail] = useState("")

  function handleChange(event) {
    event.preventDefault();
    console.log(event.target.value)
    setEmail(event.target.value);
  }

  return (
    <Fragment>
      <Box mx="auto" maxWidth={1200}>
        <Flex direction="row" mx={8} wrap="wrap" justifyContent="space-between">
          <Flex
            direction="column"
            width={["100%", null, "40%", null]}
            alignItems="center"
            px={4}
            my={[5, null, 0, null]}
          >
            <Heading
              fontSize="4xl"
              mb={4}
              textAlign="left"
              alignSelf="flex-start"
            >
              <FormattedMessage id="homepage.keep_in_touch" />
            </Heading>
            <Text textAlign="justify" fontSize="lg" mb={4} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "homepage.contact_text" }) }} />
            <form
              css={css`
                width: 100%;
              `}
            >
              <FormControl my={4}>
                <FormLabel htmlFor="email">
                  <FormattedMessage id="homepage.your_email" />
                </FormLabel>
                <FormattedMessage id="homepage.your_email">
                  {txt => (
                    <Input
                      id="email"
                      name="email"
                      placeholder={txt}
                      _hover={{ borderColor: "purple.300" }}
                      borderWidth={3}
                      borderStyle="solid"
                      borderColor="white"
                      py={5}
                      value={email}
                      onChange={handleChange}
                    />
                  )}
                </FormattedMessage>
              </FormControl>
              <LightHoverButton onClick={onOpen}>
                <FormattedMessage id="homepage.send_email" />
              </LightHoverButton>
            </form>
          </Flex>
          <Flex direction="column" width={["100%", null, "50%", null]}>
            <Box width="100%" height="350px">
              <GoogleMapsIframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2539.60884576581!2d30.50864021624841!3d50.467008194052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce6b69b3d81f%3A0x1cc2b0f95e8f3f4e!2sYaroslavska%20St%2C%2010%2C%20Kyiv%2C%20Ukraine%2C%2002000!5e0!3m2!1sen!2spl!4v1624535897417!5m2!1sen!2spl"
                allowfullscreen=""
                loading="lazy"
              />
            </Box>
            <Flex direction="row" justifyContent="flex-end">
              <SocialLink href="https://www.facebook.com/%D0%86%D0%BD%D0%B4%D1%96-%D0%9B%D0%BE%D0%B4%D0%B6%D0%B8%D1%81%D1%82%D1%96%D0%BA%D1%81-Indy-Logistics-104595288555364?notif_id=1625146941967353&notif_t=page_user_activity&ref=notif" ml={4}>
                <AiFillFacebook />
                Facebook
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/in/indy-logistics-9a9703216/" ml={4}>
                <AiFillLinkedin />
                LinkedIn
              </SocialLink>
              <SocialLink href="https://t.me/indylogistics" ml={4}>
                <FaTelegramPlane />
                Telegram
              </SocialLink>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box as="footer" textAlign="center" py={4}>
        {intl.formatMessage({ id: "footer.arr" })}
      </Box>
      <ContactForm startingEmail={email} isOpen={isOpen} onOpen={onOpen} onClose={onClose} email={email} setEmail={setEmail} />
    </Fragment>
  )
}

export default injectIntl(Footer)
