import React from "react"
import {
  Box,
  Link as ChakraLink,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
  Button,
  Stack,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  useDisclosure,
} from "@chakra-ui/core"
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import Img from "gatsby-image"

const pages = [
  {
    localeId: "header.about",
    address: "/about",
  },
  {
    localeId: "header.news",
    address: "/news",
  },
  {
    localeId: "header.contact",
    address: "/contact",
  },
  {
    localeId: "header.team",
    address: "/team",
  },
]

const langs = ["en", "ua", "ru"]

const EndExtractor = /^\/\w{2}\//

const Header = props => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const intl = useIntl()
  const buttonRef = React.useRef()
  const image = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 110) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <>
      <Stack isInline spacing={4} align="center" mx={4}>
        <Link to="/">
          <Img fixed={image.file.childImageSharp.fixed} />
        </Link>
        <Box mx="auto" />
        {pages.map(page => (
          <ChakraLink
            key={page.localeId}
            as={Link}
            to={page.address}
            fontWeight="semibold"
            _hover={{ color: "purple.500" }}
            display={["none", null, "initial", null]}
          >
            <FormattedMessage id={page.localeId} />
          </ChakraLink>
        ))}
        <Menu>
          <MenuButton
            as={Button}
            display={["none", null, "initial", null]}
            rightIcon="chevron-down"
            variant="link"
            variantColor="teal"
            _hover={{ color: "purple.500" }}
          >
            {intl.locale.toLocaleUpperCase()}
          </MenuButton>
          <MenuList>
            {langs.map(
              lang =>
                lang !== intl.locale && (
                  <MenuItem
                    key={lang}
                    as={GatsbyLink}
                    to={`/${lang}/${
                      props.location.pathname.split(EndExtractor)[1]
                    }`}
                    color="teal.500"
                    _hover={{ color: "purple.500" }}
                    fontWeight="bold"
                  >
                    {lang.toLocaleUpperCase()}
                  </MenuItem>
                )
            )}
          </MenuList>
        </Menu>
        <IconButton
          display={["initial", null, "none", null]}
          icon="chevron-down"
          ref={buttonRef}
          onClick={onOpen}
        />
      </Stack>
      <Drawer
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        finalFocusRef={buttonRef}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Link to="/">
              <Img fixed={image.file.childImageSharp.fixed} />
            </Link>
          </DrawerHeader>

          <DrawerBody>
            <Stack>
              {pages.map(page => (
                <ChakraLink
                  key={page.localeId}
                  as={Link}
                  to={page.address}
                  fontSize="xl"
                  fontWeight="semibold"
                  _hover={{ color: "purple.500" }}
                >
                  <FormattedMessage id={page.localeId} />
                </ChakraLink>
              ))}
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon="chevron-down"
                variant="link"
                variantColor="teal"
                fontSize="xl"
                _hover={{ color: "purple.500" }}
              >
                {intl.locale.toLocaleUpperCase()}
              </MenuButton>
              <MenuList>
                {langs.map(
                  lang =>
                    lang !== intl.locale && (
                      <MenuItem
                        key={lang}
                        as={GatsbyLink}
                        to={`/${lang}/${
                          props.location.pathname.split(EndExtractor)[1]
                        }`}
                        color="teal.500"
                        _hover={{ color: "purple.500" }}
                        fontWeight="bold"
                      >
                        {lang.toLocaleUpperCase()}
                      </MenuItem>
                    )
                )}
              </MenuList>
            </Menu>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Header
