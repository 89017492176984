import React from "react"
import { Button } from "@chakra-ui/core"

const LightHoverButton = props => (
  <Button
    size="lg"
    variant="solid"
    variantColor="teal"
    _hover={{ bg: "teal.400" }}
    {...props}
  />
)

export default LightHoverButton
