import React, { useState } from "react"
import { Box } from "@chakra-ui/core"

import Header from "./Header"
import Footer from "./Footer"
import ContactForm from "./ContactForm"

const Layout = props => {
  const [email, setEmail] = useState("")
  return (
    <>
      <Box
        pt={4}
        backgroundImage={props.bg}
        backgroundRepeat="no-repeat"
        backgroundColor="#f9f9f9"
        backgroundSize="contain"
        backgroundAttachment="scroll"
      >
        <Box maxWidth={1200} mx="auto">
          <Header location={props.location} />
          <Box mt={16} mx="3rem">
            {props.children}
          </Box>
        </Box>
        <Footer
          isOpen={props.isOpen}
          onOpen={props.onOpen}
          onClose={props.onClose}
        />
      </Box>
      {props.isOpen && (
        <ContactForm
          isOpen={props.isOpen}
          onOpen={props.onOpen}
          onClose={props.onClose}
          email={email}
          setEmail={setEmail}
        />
      )}
    </>
  )
}

export default Layout
