import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Textarea,
} from "@chakra-ui/core"
import { useIntl } from "gatsby-plugin-intl"

import LightHoverButton from "../components/chakraOverrides/LightHoverButton"

const typesOfToasts = {
  success: {
    status: "success",
    title_id: "cf.success.title",
    description_id: "cf.success.description",
  },
  error: {
    status: "error",
    title_id: "cf.error.title",
    description_id: "cf.error.description",
  },
}

const ContactForm = props => {
  const intl = useIntl()
  const { isOpen, onClose, email, setEmail } = props

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{intl.formatMessage({ id: 'contact.heading' })}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
            <form action="https://formspree.io/f/xwkaqdbp" method="POST">
              <ModalBody>
                <FormControl isRequired>
                  <FormLabel htmlFor="email">{intl.formatMessage({ id: 'cf.labels.email' })}</FormLabel>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="name">{intl.formatMessage({ id: 'cf.labels.name' })}</FormLabel>
                  <Input
                    id="name"
                    name="name"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="subject">{intl.formatMessage({ id: 'cf.labels.subject' })}</FormLabel>
                  <Textarea
                    id="subject"
                    name="subject"
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <LightHoverButton
                  type="submit"
                >
                  {intl.formatMessage({ id: 'cf.labels.send_email' })}
                </LightHoverButton>
              </ModalFooter>
            </form>
      </ModalContent>
    </Modal>
  )
}

export default ContactForm
